import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { default as Old2005BlogPage, doNothingAnchor } from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—December 2007" />
	<Old2005BlogPage>
		<h2>December 2007</h2>



		<h3 className="blogdate">
		Friday, December 21, 2007
		</h3>






		<h4 className="blogitemtitle">
		Dear Steven and George
		</h4>





			<div className="blogitembody">
			0h boy oh boy please oh please oh please Steven Spielberg and George Lucas when you make <span style={{fontStyle: "italic"}}>Indiana Jones and the Kingdom of the Crystal Skull</span> can you please make it super old school awesome like the old days with the old movies it will be so great if you do i will love you long time (breath) oh boy oh boy please oh please can you keep the computer generated graphics to a minimum or maybe even none nil nada because you know how you used to do it at ILM it was so cool you made models and special sets and with special lighting and it was real movie making and it had just the right amount of cheese and i love cheese especially aged crotonese and Indiana Jones (breath) oh boy oh boy i soooo look forward to viewing your movie the much anticipated sequel in the revered series while seated up close to a big screen just like when i was a kid pressed up to the screen in <span style={{fontStyle: "italic"}}>The Last Crusade</span> oh yes i will be in the movie theater as soon as i can get there don't you worry and maybe you can do that trick again with the paramount logo in the first epic film where it morphs into the actual mountain that was cool (breath) thanks oh and Harrison Ford if i was a lady of taste and distinction i would fling myself at you
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 7:27 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Thursday, December 20, 2007
		</h3>






		<h4 className="blogitemtitle">
		Christmas sours summer Down Under
		</h4>





			<div className="blogitembody">
			Ah, summer... a time of kicking back, relaxing, barbeques, outdoor adventures, extended hours of beautiful daylight, swimming, camping, road trips, and general fun, and... Christmas?!?!<br /><br />Christmas - a time of hustle and bustle, with people mashed together like potatoes in the big consumer pot, shopping and shopping and shopping to mangled festive music seeping out of store stereo systems.  The pressure builds, anxiety flares, frustration lashes out.... until the big day pops... and then everyone shuts down for a week.<br /><br />I have realized that living in the northern hemisphere has an advantage over living in the southern hemisphere.  In the south, the Christmas epidemic runs rampant at the brink of summer, diminishing it (summer) for two weeks or more.  In the north, Christmas occurs at the brink of winter, when the sun barely graces the land with its glamorous rays, and in no way affects our precious summer!<br /><br />Christmas was meant to be in the winter.  Sleigh rides loop through moon or star-lit snow and return to warm winter fires.  Ovens floweth-over with baked goodies and hams and turkeys, warming homes and appetites.  Santa Claus, who lives in the North Pole (where there is lots of snow) has a sleigh, and sleighs are meant to run in snow.<br /><br />The other day I was baking some Christmas cookies in a friend's kitchen; it got so hot that when I was done I went outside to cool off and let the summer breeze and sunshine dry my shirt.  It was weird.<br /><br />I'm probably being an ignoramus... I just got off the phone with a Kiwi who says he knows it no other way - this is how Christmas is and should be.  He doesn't "buy" the whole "snow and sleigh-bells deal" that the north propagates.  Christmas, to him, is warm, long sunny days with barbeques...  however, I did coerce him to agree that the north gets summer, *plus* a Christmas festive season - the south has the two lumped unfairly together.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 4:33 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Tuesday, December 18, 2007
		</h3>






		<h4 className="blogitemtitle">
		CD Review: Seventeen Oh-Two Oh-Six
		</h4>





			<div className="blogitembody">
			This review of <span style={{fontStyle: "italic"}}>Seventeen Oh-Two Oh-Six</span> is quite old now, but I neglected to acknowledge it on my site... so here it is.  It's from LucidForge.com, a web-based arts review.<br /><ul><li><a href="/" onClick={doNothingAnchor}>(long since removed)</a><br /></li></ul>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:09 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Tuesday, December 11, 2007
		</h3>






		<h4 className="blogitemtitle">
		Not receiving newsletter updates?
		</h4>





			<div className="blogitembody">
			Just a quick note to say that if you are on my mailing list, and you are not receiving newsletter updates... it's because I haven't been sending any.  Oh, but wait!  I just sent one last week and it was real nice!<br /><br />If you didn't get that one, then let me know... you need to have <span style={{fontStyle: "italic"}}>(email long since changed)</span> in your "friends/safe" list, or in your address book, so that there is a hope (in this wicked world of spam filters) that you will receive my newsletters.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:22 PM &nbsp;
			</div>




		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
